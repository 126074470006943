.Header {
  display: flex;
  height: 80px;
  position: block;
  top: 0;
  width: 100%;
  z-index: 2;
}

.Header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.Header-logo {
  color: #fff;
  font-weight: 300;
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: uppercase;
}


#sm-logo { display: none; }

.Header-nav {
  margin: 0;
  padding: 0;
}

.Header-nav ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 140px;
}

.Header-nav li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.Header-nav .twitter,
.Header-nav .github,
.Header-nav .linkedin {
  line-height: inherit;
}

.Header-nav li i {
  border-radius: 4px;
  height: 22px;
  line-height: 22px;
  margin: 0;
  position: relative;
  width: 24px;
}


.Header-nav li i:hover {
  background: #fff;
}

.Header a:link,
.Header a:visited {
  color: #fff;
  text-decoration: none;
}

.Header .linkedin:hover {
  color: rgb(45, 133, 188);
}

.Header .github:hover {
  color: rgb(65, 131, 196);
}

.Header .twitter:hover {
  color: rgb(85, 172, 238);
}

@media (max-width: 768px) {
  .Header {
    height: 70px;
  }

  .Header-container {
    margin: 0;
    padding: 0 1rem;
  }
  .Header-logo {
    font-size: 1.2rem;
  }

  .Header-nav ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100px;
  }
}
