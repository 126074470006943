
.App-main {
  background-color: rgba(254, 252, 252, 0.9);
  display: flex;
  font-family: "Nunito", sans-serif;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 820px;
  position: relative;
  width: 100%;
}

.App-wrap {
  margin: 2rem;
}

.App-container {
  margin: 1rem 0;
}

.App-section-heading {
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.App-heading {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.App-description {
  margin: 0.4rem 0;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 0.9rem;
}

.App-timeline {
  margin: 0.4rem 0;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 0.9rem;
  font-style: italic;
}

.App-footer {
  height: 200px;
  margin: 1rem 0 0;
  width: 100%;
}
